// Yellow Gradient: bg-gradient-to-r from-[#FDB447] to-[#FDEE50]
// Blue Gradient: bg-gradient-to-r from-uk-blue to-[#85B3FE]

export enum Colors {
    Dark = '#1b1b1b',
    Gray = '#817c7c',
    White = '#f1f1f1',
    Orange = '#fe6007',
    Blue = '#06346c',
    Darkblue = '#062953',
    Creamy = '#FFEFE6',
}
