<script lang="ts" setup>
import {Colors} from '~~/layers/uikit/enum/colors'
import TelegramBotButton from '~~/app/components/TelegramBotButton.vue'

const route = useRoute()
const appConfig = useAppConfig()
const runtimeConfig = useRuntimeConfig()
const env = useEnvironment()

const configStore = useConfigStore()
const contextStore = useContextStore()

useHead({
  titleTemplate(title) {
    return title ? `${title} | ${appConfig.title}` : appConfig.title
  },
  link: () => [
    {
      rel: 'canonical',
      href: `${runtimeConfig.public.baseUrl}${route.path}`,
    },
  ],
})

useSeoMeta({
  ogDescription:
    'Учебно-тренажерный центр Флагман Ростов-на-Дону. Обучение профессиям морского, речного и берегового сектора. Повышение квалификации моряков. Помощь с дальнейшим трудоустройством',
  ogTitle: appConfig.title,
  ogSiteName: appConfig.title,
  ogUrl: () => `${runtimeConfig.public.baseUrl}${route.path}`,
  ogImage: () => `${runtimeConfig.public.baseUrl}/images/logo.png`,
})

const tasks = [configStore.fetch(), contextStore.fetch()]

try {
  await callOnce(async () => {
    await Promise.all(tasks)
  })
} catch (error) {
  if (env.isDevelopment) {

    console.error(error)
  }
}
</script>

<template>
  <NuxtLoadingIndicator :color="Colors.Orange"/>
  <NavigationPanel/>

  <Container class="flex flex-col gap-10 lg:gap-12 pb-12 lg:pb-24">
    <slot/>
  </Container>

  <FooterPanel/>
  <Cookies/>
  <ShopCartPopup/>
  <TelegramBotButton/>
  <WhatsappButton/>
</template>

<style lang="scss">
@use '../../layers/uikit/scss/ckeditor';
</style>
